import React from "react";
import jeffImage from "../../images/Jeff_Img_blue.jpg";
// import { Col, Row } from "react-bootstrap";
import { 
  Container,
  Grid,
  makeStyles,
  Typography,
  Paper,
} from "@material-ui/core";

const jeffStatement =
"On April 27, our CTO Jeff Lloyd passed away. Jeff had been battling cancer since an initial diagnosis in July 2020. In the ten months since then he was a hard-working, thoughtful and professional CTO and colleague, caring deeply about the team and the company. Even when dealing with the side effects of the cancer treatments he was constantly investing time in planning and research, coaching team members and overseeing the evolution of the product to an institutional level of quality. Outside of work Jeff had an array of interests, from hockey to guitar to his love of motorcycles. He is survived by his wife and two young sons. \n" +
"All of us at Migrations.ml are thankful to have walked several miles of our journey with Jeff and benefited from knowing him. Jeff was empathetic, caring and genial. The world needs more people like Jeff, and now we are down one. We encourage anyone reading this message to invest the time in their daily lives to tell the people you love how much they mean to you.";

const useStyles = makeStyles({
    root: {
        marginTop: "8em"
    },
    blurbBox: {
        backgroundColor: "rgba(32, 53, 80, 0.62)",
        filter: "drop-shadow(0px 4px 20px rgb(10, 19, 31))",
        padding: "4rem 3rem 4rem 3rem",
    },
    title: {
        marginBottom: "2em"
    }
}); 

const RenderJeffMomoriam = () => {
    const classes = useStyles();
    return (
        <Container className={classes.root} id="jeff">
            <Paper className={classes.blurbBox}>
                <Grid 
                    container 
                    justifyContent="center" 
                    className={classes.title}    
                >
                    <Typography variant="h4">
                        IN MEMORIAM - JEFF LLOYD
                    </Typography>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid style={{textAlign: "center"}}>
                        <img
                            alt="Jeff Lloyd"
                            width="50%"
                            className="my-2"
                            src={jeffImage}
                        />
                    </Grid>
                    <Grid>
                        <p className="my-2">{jeffStatement}</p>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default RenderJeffMomoriam;