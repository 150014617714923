import React from "react";
// import { Col, Row } from "react-bootstrap";
// import {Card, CardMedia, Grid}
import {
  Container,
  Card,
  CardMedia,
  Grid,
  // Link,
  Typography,
  // CardHeader,
  // CardContent,
  // Paper,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import duncanImage from "../../images/Duncan_Img_blue.jpg";
import vikImage from "../../images/vik_Img_blue.jpg";
import davidImage from "../../images/David_Img_blue.jpg";
import jacquesImage from "../../images/Jacques_Img_blue.jpg";

const MEMBERS = {
  TEAM: {
    title: "OUR TEAM",
    members: [
      {
        "Duncan Rowland": {
          role: "FOUNDER AND CEO",
          bio: [
            "A CA/CPA, working in the Capital Markets space in Toronto and overseas for 20+ years, the last five in RBC’s Finance IT group, Duncan has spent over half his career in market and counterparty credit risk, working with traders, quantitative analysts and traditional analysts. For most of his career, a large component of his roles has been to lead and implement change in the business line and in IT.",
            "Duncan graduated from the University of Toronto in 1991 with a Bachelor of Commerce degree.",
          ],
          image: duncanImage,
        },
      },
      // {
      //   "Vikneshvar Chandrahasan": {
      //     role: "FULL-STACK DEVELOPER",
      //     bio: [
      //       "Vikneshvar has over five years of experience working on different aspects of software and business intelligence development. He has collaborated with several start-ups on ideation, product design, and development. He last worked as a Data and Analytics Software developer at CGI, implementing BI solutions for the US public sector organizations. Vikneshvar has been researching on using NLP algorithms to manage public relations and marketing in large corporations.",
      //       "Vikneshvar graduated from California State University, Fullerton, with a Masters degree in Information Systems focusing on Decision Science and Business Analytics.",
      //     ],
      //     image: vikImage,
      //   },
      // },
      // {
      //   "David Lipson": {
      //     role: "FULL-STACK DEVELOPER",
      //     bio: [
      //       "David is a skilled developer with over 5 years of experience in software design and implementation. He has collaborated with start-ups, as well as larger software firms as a lead developer on bespoke applications - from smart dashboards, to robust data pipelines and client APIs.",
      //       "David received his bachelor's degree in Computer Science and Bioinformatics from the University of Waterloo.",
      //     ],
      //     image: davidImage,
      //   },
      // },
      {
        "Jacques Nel": {
          role: "DEVELOPER",
          bio: [
            "Jacques has over 5 years of experience designing and building high performance, data-intensive systems.\n" +
              "Drawing on a background in applied mathematics and optimisation, he is well-versed in the end to end implementation of ML pipelines.",
            "As an academic researcher, at York University, he has applied a variety of generative and deep reinforcement learning architectures to financial\n" +
              "time series.",
          ],
          image: jacquesImage,
        },
      },
    ],
  },
};

const useStyles = makeStyles({
  // root: {
  //   margin: "4em 0",
  //   marginBottom: "6em",
  // },
  overlay: {
    position: "absolute",
    top: 0,
    display: "block",
    visibility: "hidden", //// *** Temporary *** /////
  },
  overlaySpacer: {
    height: "20rem",
  },
  overlayTitle: {
    color: "#95D501",
  },
  overlayBody: {
    background: "#31405F",
  },
  teamNameCapitalize: {
    textTransform: "capitalize",
  },
  cardImg: {
    zIndex: 1,
    "&:hover": {
      zIndex: 12,
    },
    "&:hover ~ $overlay": {
      visibility: "visible",
      zIndex: 10,
    },
    "& ~ $overlay": {
      visibility: "hidden",
      zIndex: -1,
    },
  },
  renderTeamTitle: {
    textAlign: "center",
    margin: "1em 0",
  },
  renderTeamIndividualRoot: {
    padding: "10px",
  },
  root: {
    minWidth: "18rem",
  },
  media: {
    height: "18rem",
  },
  popUp: {
    display: "block",
    width: "18rem",
    backgroundColor: "#31405F",
    "&:hover ~ $foo": {
      backgroundColor: "red",
    },
  },
  foo: {},
});

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#31405F",
  },
}))(Tooltip);

const TeamMemberCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid item style={{ margin: theme.spacing(5) }} elevation={2}>
      <div>
        <CustomTooltip
          placement="bottom"
          arrow
          title={
            <React.Fragment>
              <Typography variant="h6" style={{ color: "#95D501" }}>
                {props.name}, {props.role}
              </Typography>
              {props.bio.map((item, i) => (
                <Typography
                  key={i}
                  variant="body1"
                  style={{
                    marginTop: "1rem",
                    fontSize: "1rem",
                    fontWeight: "300",
                  }}
                >
                  <p>{item}</p>
                </Typography>
              ))}
            </React.Fragment>
          }
        >
          <Card className={classes.root}>
            <CardMedia image={props.image} className={classes.media} />
          </Card>
        </CustomTooltip>
      </div>
    </Grid>
  );
};

const RenderTeam = () => {
  const team = MEMBERS.TEAM;
  const title = team.title;
  const members = team.members;
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid className={classes.renderTeamTitle}>
        <Typography variant="h4" id="team">
          {title}
        </Typography>
      </Grid>

      <Grid container justifyContent="center">
        {members.map((member, i) => {
          const name = Object.keys(member)[0];
          const role = member[name].role;
          const bio = member[name].bio;
          const image = member[name].image;
          return (
            <TeamMemberCard
              name={name}
              role={role}
              bio={bio}
              image={image}
              key={i}
            />
          );
        })}
      </Grid>
    </Container>
  );
};

export default RenderTeam;
