import React from "react";
import PageBase from "../pageBase";
import { Box, Container, Link, Paper, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import RenderJeffMomoriam from "./jeffMemoriam";
import RenderAdvisors from "./advisors";
import RenderTeam from "./team";
import migrationsLogo from "../../images/Migrations_SymbolOnly_Green_RGB.svg";

const ABOUT_STATEMENT_1 =
  "The Migrations.ml solution streamlines the human workflow and optimizes the credit analysis process in public and private debt markets. Under the supervision of renowned academics and experienced bond traders, we train our software using large data sets and machine learning algorithms.";

const useStyles = makeStyles({
  scrollLink: {
    borderBottom: "1px solid #ffffff",
    paddingBottom: "3px",
    fontWeight: "600",
    fontSize: "1.10rem",
    color: "white",
    flexGrow: 0,
    marginLeft: "1rem",
    marginRight: "1rem",
    display: "inline-block",
    "&:hover": {
      cursor: "pointer",
      color: "#97d700",
    },
  },
});

const AboutPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <PageBase>
      <Container maxWidth="lg" style={{ marginTop: theme.spacing(5) }}>
        <Paper elevation={1} style={{ backgroundColor: "#172941" }}>
          <Container maxWidth="md">
            <Box p={5} display="flex" flex={1} flexDirection="column">
              <Box alignSelf="center">
                <img src={migrationsLogo} alt="logo" height={80} />
              </Box>

              <Box alignSelf="center">
                <Typography
                  variant="overline"
                  style={{ fontSize: "1.5rem", fontWeight: 800 }}
                >
                  Who we are
                </Typography>
              </Box>
              <Box alignSelf="center" m={2}>
                <Typography variant="body1" align="center">
                  {ABOUT_STATEMENT_1}
                </Typography>
              </Box>
              <Box alignSelf="center" m={2}>
                <Link href="#team" className={classes.scrollLink}>
                  OUR TEAM
                </Link>
                <Link href="#advisors" className={classes.scrollLink}>
                  OUR ADVISORS
                </Link>
                <Link href="#jeff" className={classes.scrollLink}>
                  JEFF LLOYD-IN MEMORIAM
                </Link>
              </Box>
            </Box>
          </Container>
        </Paper>

        <RenderTeam />
        <RenderAdvisors />
        <RenderJeffMomoriam />
      </Container>
    </PageBase>
  );
};

export default AboutPage;
