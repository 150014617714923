import React, { useState } from "react";
import { Card } from "react-bootstrap";
import {
  Container,
  Grid,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import cx from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
} from "@fortawesome/pro-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import alyImage from "../../images/Aly_Img_blue.jpg";
import reenaImage from "../../images/Reena_Img.jpg";
import walterImg from "../../images/Walter_Img.jpg";
import brianImg from "../../images/Brian_Img.jpg";
import gabrielaImg from "../../images/Gabriela_Img.jpg";
import gregImg from "../../images/Greg_Img.jpg";
import tonyImg from "../../images/Tony_Img.jpg";
import jayImage from "../../images/Jay_Img.jpg";
import steveImage from "../../images/Steve_Img_white.jpg";
import joshuaImage from "../../images/Joshua2_Img.jpg";
import paulImage from "../../images/Paul_Img.jpg";
import stuartImage from "../../images/Stuart_Img.jpg";

const MEMBERS = {
  ADVISORS: {
    title: "OUR ADVISORS",
    members: [
      {
        "Jay Vyas": {
          role: "ADVISOR",
          linkedIn: "https://www.linkedin.com/in/jay-vyas-cfa-3b678",
          bio: [
            "Jay has over three decades of experience in the field of quantitative investing at leading global investment organizations. He is well-versed in all aspects of the systematic investing - research, portfolio construction, process design, data, analytics, and technology/infrastructure.",
            "He holds a BS in Electrical Engineering and Computer Science from the University of California, Berkeley and is a CFA charterholder.",
          ],
          image: jayImage,
        },
      },
      {
        "Aly Kachra": {
          role: "ADVISOR",
          bio: [
            "Aly has 20+ years of expertise in Capital Markets spanning Hedge Funds, Portfolio Construction and Risk Analytics. He traded high yield bonds, structured credit and credit default swaps up and down the capital structure. He has also done extensive research on credit risk models from a factor perspective.",
            "Aly holds a Masters in Finance from London Business School and has broad-based experience across public and private assets.",
          ],
          image: alyImage,
          linkedIn: "https://www.linkedin.com/in/aly-kachra-20551631/",
        },
      },
      {
        "Reena Chaudhary": {
          bio: [
            "Reena is Managing Partner of RG Advisory, the first women-owned and led Capital Markets Regulatory firm in Canada. Prior to RG Advisory, Reena was an IBM Management Consultant providing risk management & data transformation services to Canadian Financial Institutions. She holds a BComm degree from the University of Toronto.",
          ],
          role: "ADVISOR",
          linkedIn: "https://www.linkedin.com/in/reena-chaudhary-9551159/",
          image: reenaImage,
        },
      },
      {
        "Greg Cripps": {
          bio: [
            "Greg has a broad experience of the financial data eco-system earned as an Angel Investor and Business Advisor at Quandl and as co-Founder of Prism Valuation. He managed derivatives trading desks and portfolios in London and Toronto for TD Securities and Citibank. Greg received a PhD in Nuclear Engineering from McMaster University in 1993.",
          ],
          role: "ADVISOR",
          linkedIn: "https://www.linkedin.com/in/greg-cripps-00936b4/",
          image: gregImg,
        },
      },
      {
        "Stuart Turnbull": {
          bio: [
            "Paul has three decades of experience in Fixed Income Sales, Trading and Capital Markets. Originally from Toronto, where he spent 10 years with Citibank, Paul has been in New York for over 20 years where he was Head of U.S. Fixed Income for National Bank of Canada Financial.",
            "Paul has an MBA from the Ivey Business School, and is a CFA charterholder.",
          ],
          role: "ADVISOR",
          linkedIn: "https://www.linkedin.com/in/stuart-turnbull-777629b/",
          image: stuartImage,
        },
      },
      // {
      //   "Paul Badeski": {
      //     bio: [
      //       "Paul has three decades of experience in Fixed Income Sales, Trading and Capital Markets. Originally from Toronto, where he spent 10 years with Citibank, Paul has been in New York for over 20 years where he was Head of U.S. Fixed Income for National Bank of Canada Financial.",
      //       "Paul has an MBA from the Ivey Business School, and is a CFA charterholder.",
      //     ],
      //     role: "ADVISOR",
      //     linkedIn: "https://www.linkedin.com/in/paulbadeski/",
      //     image: paulImage,
      //   },
      // },
    ],
    hiddenmembers: [
      {
        "Joshua Izzard": {
          role: "ADVISOR",
          bio: [
            "Joshua is an investor and tech innovator working to leverage data analytics into successful and profitable products. His experience spans investment banking, portfolio management, and application development at large banks and startups in Russia and the USA. Joshua has an MBA from the University of Chicago and a Master of Arts from the Moscow State Conservatory.",
          ],
          image: joshuaImage,
          linkedIn: "https://www.linkedin.com/in/joshuaizzard",
        },
      },
      {
        "Paul Badeski": {
          bio: [
            "Paul has three decades of experience in Fixed Income Sales, Trading and Capital Markets. Originally from Toronto, where he spent 10 years with Citibank, Paul has been in New York for over 20 years where he was Head of U.S. Fixed Income for National Bank of Canada Financial.",
            "Paul has an MBA from the Ivey Business School, and is a CFA charterholder.",
          ],
          role: "ADVISOR",
          linkedIn: "https://www.linkedin.com/in/paulbadeski/",
          image: paulImage,
        },
      },
      // {
      //   "Richard Southwick": {
      //     bio: [
      //       "Richard is a technology leader with broad experience in engineering management and software engineering. As CTO of Lending Club, Richard built and led a technology organization through rapid growth and an IPO.",
      //       "He holds a doctorate in Computing from Imperial College of Science and Technology, London.",
      //     ],
      //     role: "ADVISOR",
      //     linkedIn: "https://www.linkedin.com/in/rwsouthwick",
      //     image: richardImg,
      //   },
      // },
      {
        "Tony Sevsek": {
          bio: [
            "Tony is a bond market expert, a fintech investor, and a crypto enthusiast. He has been a leader in bringing innovation to the Canadian crypto asset industry and has worked in bond trading and sales at BMO Capital Markets and Merril Lynch for two decades. Tony holds Engineering and MBA degrees from the University of Toronto.",
          ],
          role: "ADVISOR",
          linkedIn: "https://www.linkedin.com/in/tsevsek/",
          image: tonyImg,
        },
      },
      {
        "Walter de Wet": {
          bio: [
            "Walter has more than 15 years of experience as a strategist within a Global Markets environment, focusing on macroeconomic modelling, commodities, fixed income and foreign exchange markets. Walter holds an MA in Economics (University of Toronto) and a PhD in Econometrics (University of Pretoria). He is also a CFA charterholder.",
          ],
          role: "ADVISOR",
          linkedIn: "https://www.linkedin.com/in/walterdewet/",
          image: walterImg,
        },
      },
      {
        "Steve Shaw": {
          role: "ADVISOR",
          bio: [
            "Steve has over twenty years of experience in leading teams of varying sizes in consulting and product environments. He has worked with Fortune 500 companies to start-ups, in leadership and hands-on development roles. He has successfully guided engineering organizations through due diligence reviews during acquisition and funding rounds. Steve specializes in bridging the gap between what the business side wants and what the development team can provide.",
            "Steve graduated from the University of Waterloo in 1995 with a Bachelor of Mathematics in Computer Science.",
          ],
          image: steveImage,
          linkedIn: "https://www.linkedin.com/in/stevefshaw",
        },
      },
      {
        "Gabriela Mateescu": {
          bio: [
            "Managing Director at RG Advisory, with 20+ years of experience in the Capital Markets domain delivering end-to-end solutions for large financial institutions. Gabriela provides expertise to top tier banks in the areas of G20 derivatives regulations, Front Office and Data Modernization. She holds a Masters degree in Computer Science from The Polytechnic University of Bucharest.",
          ],
          role: "ADVISOR",
          linkedIn: "https://www.linkedin.com/in/gabriela-mateescu-76407221/",
          image: gabrielaImg,
        },
      },
      {
        "Brian Archdekin": {
          bio: [
            "Brian has more than 25 years of experience in Capital Markets, in senior sales relationship management roles, developing strategies to promote mutually beneficial business by connecting with senior level investors to understand their priorities and investment focus. Brian has a BA (Math for Commerce) from York University and is a CFA charterholder.",
          ],
          role: "ADVISOR",
          linkedIn: "https://www.linkedin.com/in/brianarchdekin/",
          image: brianImg,
        },
      },
    ],
  },
};

const useStyles = makeStyles({
  root: {
    padding: "3em",
    backgroundColor: "rgb(18,34,61)",
  },
  moreAdvisors: {
    cursor: "pointer",
    color: "white",
    "&:hover": {
      color: "#95D501",
    },
    marginTop: "2em",
  },
  singleAdvisor: {
    color: "white",
    "&:hover": {
      color: "#97d700",
      cursor: "default",
    },
  },
  advisorCard: {
    maxWidth: "10rem",
    backgroundColor: "rgba(49, 64, 95, 0)",
    position: "relative",
  },
  linkedInLogo: {
    color: "#0e76a8",
    position: "absolute",
    top: "6px",
    left: "6px",
  },
  linkedImg: {
    "& $linkedInLogo": {
      opacity: 0,
    },
    "&:hover $linkedInLogo": {
      opacity: 1,
    },
  },
  advisorName: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
    fontWeight: "normal",
  },
});

const renderIndividualAdvisors = (member, classes, i) => {
  const name = Object.keys(member)[0];
  // const role = member[name].role;
  // const bio = member[name].bio;
  const image = member[name].image;
  const linkedIn = member[name].linkedIn;

  // const bioDivs = bio.map((paragraph) => {
  //   return (
  //     <p className="my-1 font-weight-light" style={{ fontSize: "0.9rem" }}>
  //       {paragraph}
  //     </p>
  //   );
  // });

  return (
    <Grid
      item
      xs={4}
      sm={4}
      lg={2}
      md={3}
      key={i}
      style={{ padding: "15px" }}
      className={classes.singleAdvisor}
    >
      <Card
        className={cx(
          "m-2 p-0 border-0 justify-content-center",
          classes.advisorCard
        )}
      >
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={linkedIn}
          className={classes.linkedImg}
        >
          <Card.Img varaint="top" src={image} width="100%" />
          <FontAwesomeIcon
            fixedWidth
            icon={faLinkedin}
            hidden={linkedIn ? false : true}
            size="lg"
            className={classes.linkedInLogo}
          />
        </Link>
        <Typography
          style={{ fontSize: "14px" }}
          className={cx(
            "ps-1 pb-1",
            classes.advisorName,
            classes.advisorNameCapitalize
          )}
        >
          {name}
        </Typography>
      </Card>
    </Grid>
  );
};

const RenderAdvisors = (props) => {
  const [moreAdvisorsHidden, setMoreAdvisorsHidden] = useState(false);
  const classes = useStyles();
  const advisors = MEMBERS.ADVISORS;
  const title = advisors.title;
  const members = advisors.members;
  const hiddenMembers = advisors.hiddenmembers;
  const icon = moreAdvisorsHidden ? faAngleDoubleUp : faAngleDoubleDown;

  const moreAdvisors = () => {
    const newState = !moreAdvisorsHidden;
    setMoreAdvisorsHidden(newState);
  };

  return (
    <Container>
      <Grid style={{ textAlign: "center" }}>
        <Typography variant="h4" id="advisors">
          {title}
        </Typography>
      </Grid>

      <Grid container>
        <Grid container>
          {members.map((members, i) =>
            renderIndividualAdvisors(members, classes, i)
          )}
          {moreAdvisorsHidden ? (
            hiddenMembers.map((members, i) =>
              renderIndividualAdvisors(members, classes, i)
            )
          ) : (
            <></>
          )}
        </Grid>
        <Grid container justifyContent="center" onClick={() => moreAdvisors()}>
          <div className={classes.moreAdvisors}>
            {moreAdvisorsHidden ? "Hide" : "See More Advisors "}
            <FontAwesomeIcon
              size="sm"
              icon={icon}
              style={{ margin: "4px 6px 0" }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RenderAdvisors;
